import React, { useState, useEffect, Suspense, lazy } from 'react';
import '../styles/index.css';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { AppContext } from '../contexts';
import SecureRoute from '../components/SecureRoute';
import { isEmpty } from '../callforce-ui/utils';
import { setItem, getItem } from '../localforage';

const Header = lazy(() => import('../components/Header'));
const Login = lazy(() => import('./Auth/Login'));
const AuthAccount = lazy(() => import('./AuthAccount'));
const ResetPassword = lazy(() => import('./AuthAccount/ResetPassword'));

const App = ({
  account,
  auth,
  firestore,
  firebase,
  accountOffices,
  authenticated,
  selectedOfficeId
}) => {
  const [accountOfficesInfo, setAccountOfficesInfo] = useState([]);
  const [offeringsCategories, setOfferingsCategories] = useState({});
  const [selectedParamOfficeId, setSelectedParamOfficeId] = useState('');
  const [activeServices, setActivesServices] = useState({});
  const [fullPageNotification, setFullPageNotification] = useState({
    open: false,
    message: (
      <span>
        Your account is at risk of suspension due to an unpaid invoice. To pay
        your invoice, or if you believe this is an error, please reach out to
        our billing team at{' '}
        <strong>
          <a href='mailto:billing@getcallforce.com?subject=Account Pending Suspension'>
            billing@getcallforce.com
          </a>
        </strong>
      </span>
    ),
    fullPageActive: false
  });
  const [toastNotification, setToastNotification] = useState(false);

  const turnOffFullPage = () => {
    setFullPageNotification({ ...fullPageNotification, open: false });
  };

  useEffect(() => {
    if (!selectedParamOfficeId && selectedOfficeId) {
      setSelectedParamOfficeId(selectedOfficeId);
    }
  }, [selectedOfficeId, selectedParamOfficeId]);

  // Clearing account data upon logout.
  useEffect(() => {
    if (account.isEmpty) {
      setAccountOfficesInfo([]);
      setSelectedParamOfficeId('');
      if (fullPageNotification.fullPageActive || toastNotification) {
        setFullPageNotification({
          ...fullPageNotification,
          open: false,
          fullPageActive: false
        });
        setToastNotification(false);
      }
    }
  }, [account, fullPageNotification, toastNotification]);

  // initial retrieval of offeringsCategories.
  useEffect(() => {
    const checkLocalForage = async () => {
      const results = await getItem('offeringsCategories');
      if (results !== null) {
        setOfferingsCategories(results);
      } else {
        retrieveOfferings();
      }
    };

    const retrieveOfferingSubcategories = async offeringId => {
      const subcategories = [];
      const offeringSubcategoriesSnap = await firestore
        .collection('offeringsCategories')
        .doc(offeringId)
        .collection('subcategories')
        .get();

      offeringSubcategoriesSnap.forEach(subcategoryOffering => {
        subcategories.push({
          categoryId: offeringId,
          id: subcategoryOffering.id,
          ...subcategoryOffering.data()
        });
      });

      return subcategories;
    };

    const retrieveOfferings = async () => {
      const offeringCategories = [];
      const categoriesHash = {};
      const offeringSubcategoryPromises = [];
      const offeringCategoriesSnap = await firestore
        .collection('offeringsCategories')
        .get();

      offeringCategoriesSnap.forEach(offering => {
        offeringCategories.push({ id: offering.id, ...offering.data() });
      });

      for (const offering in offeringCategories) {
        const offeringObj = offeringCategories[offering];
        const { id } = offeringObj;
        offeringSubcategoryPromises.push(retrieveOfferingSubcategories(id));
        categoriesHash[id] = { ...offeringObj };
        categoriesHash[id].subcategories = {};
      }

      const subcategoryRes = await Promise.allSettled(
        offeringSubcategoryPromises
      );
      const errs = subcategoryRes.filter(promise => promise.status === 'error');
      if (errs.length) {
        console.error(errs);
      }

      for (const subcategoryPromise in subcategoryRes) {
        const subcategoryObj = subcategoryRes[subcategoryPromise];
        if (subcategoryObj.status === 'fulfilled') {
          const { value } = subcategoryObj;
          for (const subcategoryVals in value) {
            const subcategory = value[subcategoryVals];
            if (categoriesHash[subcategory.categoryId]) {
              categoriesHash[subcategory.categoryId].subcategories[
                subcategory.id
              ] = { ...subcategory };
              delete categoriesHash[subcategory.categoryId].id;
              delete categoriesHash[subcategory.categoryId].subcategories[
                subcategory.id
              ].categoryId;
              delete categoriesHash[subcategory.categoryId].subcategories[
                subcategory.id
              ].id;
            }
          }
        }
      }

      await setItem('offeringsCategories', categoriesHash);
      setOfferingsCategories(categoriesHash);
    };

    if (authenticated && isEmpty(offeringsCategories)) {
      checkLocalForage();
    }
  }, [offeringsCategories, firestore, authenticated]);

  // handling clientGroup, selectedOfficeInfo, and setting of offices/regions.
  useEffect(() => {
    const retrieveOfficeDetails = async officeId => {
      try {
        const officeSnap = await firestore
          .collection('clients')
          .doc(officeId)
          .get();

        const office = officeSnap.exists ? officeSnap.data() : null;
        return { officeId, ...office };
      } catch (e) {
        return { officeId, error: true, ...e };
      }
    };

    const retrieveAccountOfficesInfo = async accountOfficesIds => {
      const promises = [];

      for (const accountOfficeId of accountOfficesIds) {
        promises.push(retrieveOfficeDetails(accountOfficeId));
      }

      const officesRes = await Promise.allSettled(promises);
      const errs = officesRes.filter(promise => promise.status === 'error');
      if (errs.length) {
        console.error(errs);
      }
      const officesInfo = officesRes
        .map(promise => {
          let value;
          if (!promise.value.error) value = promise.value;
          return value;
        })
        .filter(res => res);

      const activeServices = gatherActiveOfferingsFromAllAccountOffices(
        officesInfo
      );

      collectNotifications(officesInfo);
      setActivesServices(activeServices);
      setAccountOfficesInfo(officesInfo);
    };

    const collectNotifications = officesInfo => {
      let toast = false,
        fullPage = false;

      for (const office of officesInfo) {
        // capturing 'overdue' and 'pending suspension' statuses for billing only for now.
        if (office.opsStatuses && office.opsStatuses.billing !== 'current') {
          const {
            opsStatuses: { billing }
          } = office;

          if (billing === 'overdue') {
            toast = true;
          } else if (billing === 'pending suspension') {
            fullPage = true;
          }
        }
      }

      if (fullPage) {
        setFullPageNotification({
          ...fullPageNotification,
          open: true,
          fullPageActive: true
        });
      } else if (toast) {
        setToastNotification(true);
      }
    };

    const gatherActiveOfferingsFromAllAccountOffices = officesInfo => {
      const offerings = {
        answering: {
          offices: []
        },
        recall: {
          offices: []
        },
        websiteChat: {
          offices: []
        },
        treatment: {
          offices: []
        },
        insuranceVerifications: {
          offices: []
        }
      };

      for (const office in officesInfo) {
        const officeObj = officesInfo[office];
        if (officeObj.offerings.answering) {
          offerings.answering.offices.push(officeObj);
        }
        if (officeObj.offerings.recall) {
          offerings.recall.offices.push(officeObj);
        }
        if (officeObj.offerings.websiteChat) {
          offerings.websiteChat.offices.push(officeObj);
        }
        if (officeObj.offerings.treatment) {
          offerings.treatment.offices.push(officeObj);
        }
        if (officeObj.offerings.insuranceVerification) {
          offerings.insuranceVerifications.offices.push(officeObj);
        }
      }

      return offerings;
    };

    if (!accountOfficesInfo.length && accountOffices?.length) {
      retrieveAccountOfficesInfo(accountOffices);
    }
  }, [
    firestore,
    selectedOfficeId,
    accountOfficesInfo,
    accountOffices,
    fullPageNotification
  ]);

  const handleSignUp = () => {
    const { REACT_APP_CONTACT_SALES_LINK } = process.env;
    window.location.replace(REACT_APP_CONTACT_SALES_LINK);
  };

  const handleIdParamUpdate = id => {
    setSelectedParamOfficeId(id);
  };

  const state = {
    authenticated,
    auth,
    account,
    firestore,
    firebase,
    selectedParamOfficeId,
    handleIdParamUpdate,
    accountOfficesInfo,
    offeringsCategories,
    accountOffices,
    activeServices,
    host: `${window.location.protocol}//${window.location.hostname}`,
    toastNotification,
    fullPageNotification,
    turnOffFullPage
  };

  return (
    <Suspense fallback={null}>
      <AppContext.Provider value={{ ...state }}>
        <Switch>
          <Route
            exact
            path='/login'
            render={() => (
              <Login handleSignUp={handleSignUp} account={account} />
            )}
          />
          <Route
            path={['/create-account', '/forgot-password']}
            render={() => (
              <AuthAccount handleSignUp={handleSignUp} firebase={firebase} />
            )}
          />
          <Route
            path='/reset-password/:oobCode/:redirectUri/:emailParam/'
            render={() => <ResetPassword firebase={firebase} />}
          />
          <SecureRoute path='/' component={Header} />
        </Switch>
      </AppContext.Provider>
    </Suspense>
  );
};

const mapStateToProps = state => {
  const {
    firebase: { profile, auth }
  } = state;
  const account = profile;
  const authenticated = !!auth.uid;
  const selectedOfficeId =
    account.offices && account.offices.length ? account.offices[0] : null;
  const accountOffices = account.offices;
  const initialData = {
    account,
    auth,
    accountOffices,
    authenticated,
    selectedOfficeId
  };

  return initialData;
};

export default compose(connect(mapStateToProps), firestoreConnect())(App);
