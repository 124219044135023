import localForage from 'localforage';

export const setItem = async (key, value) => {
  await localForage.setItem(key, value);
  return `${key} was set in localForage.`;
};

export const getItem = async key => {
  const result = await localForage.getItem(key);
  return result;
};

export const removeItem = async key => {
  await localForage.removeItem(key);
  return `Removed ${key} from localForage.`;
};
