import {
  ExclamationCircle,
  Ban,
  QuestionMarkCircle,
  Clock,
  PhoneMissedCall,
  Calendar,
  CurrencyDollar,
  UserGroup,
  Truck,
  Heart,
  DotsHorizontal,
  Beaker,
  X,
  LocationMarker,
  PhoneOutbound,
  CreditCard,
  UserCircle,
  EmojiSad
} from './Icons';

export const NotScheduledIcons = {
  /**
   * NotScheduled Categories/Subcategories:
   * Categories:
   * 1uKSWhR0hXiNy7k4jPqx - 'Did Not Schedule' - Xcircle
   * 6gG0aZkUKrSQDtsOC2TJ - 'No Longer Patient' - ban
   * bP9NBokl0alM0hUSd3P5 - 'Emergency' - exclamation-circle
   * nfElqw0gt70zepPBLCdI - 'Question Before Scheduling' - question-mark-circle
   *
   * Subcategories:
   * 0sCHAadp3XDh2GJhnIAk - 'Did Not Want To Schedule' - ban
   * 4oEuKni5bczAnPnomKSb - 'Busy' - clock
   * 9ydkW9fc2SO2f8uZk9Ki - 'Dead Call' - phone-missed-call
   * CRNYsENjwsHRPAT9DN5l - 'Already Scheduled' - calendar
   * Hzqu91YsyNqvi7KgX2sD - 'Schedule Conflicts' - exclamation-circle
   * N7QhJqSrR9CVEuQecUvt - 'Offices Employee/Family Member - user-group
   * NQH8iM6TEOi0F8FR0IZF - Financial Reasons/Can't Afford - currency-dollar
   * S6Nv50rJ92J8h8LehGyl - Recent Appointment - calendar
   * V1WYjI9BdC9eyMGRWLxe - Concerns about COVID-19 - beaker
   * XwUa2mprpZdeI6lqNdJp - Wrong Number - phone-missed-call
   * YWePxsJTdKYJSiTtsb7Q - Popup (Indicated not to schedule) - exclamation-circle
   * dxpAd7eyayg0P8bNGSf9 - Billing Concerns - currency-dollar
   * f3xqv7gAGz3DVCEIK8Xf - Will Call Office When Ready - phone-outgoing
   * gloaUsnP3qoRwqDaPv6H - Medical Reasons - heart
   * jAWTyzx8e5GgoyZPBIoN - Has Dentures - dots-horizontal
   * kONFzUE0OqexdHkNCT1o - Wanted to Check Schedule First - calendar
   * xTDsCZ6cpnicnswUxyp4 - Insurance Concerns - credit-card
   * 8lFSNAcPAQjAy4EESs4v - Seeing Specialist - user-circle
   * KKAGx3ECkasmQ39bC5AG - Insurance Not in Network - credit-card
   * SRPjmzG1GR2hMI6RqwiQ - Deceased - x
   * SnDPnZijHUhgptjz50pp - Found Closer/Move Convenient Office - location-marker
   * SzVra3TKLfjbAEbLe80m - Did Not Specify - question-mark-circle
   * WaIeHb36IRLTLUjSb0xv - Bad Experience - emoji-sad
   * a1uCRGZTHJs8BrSU2ph6 - Only Came in for Treatment/Consult - dots-horizontal
   * dORa31NKqTi2HVWpR4ug - Too Expensive - currency-dollar
   * iRDioOo22NwsdIUbaacO - Moved out of the Area - truck
   * 73VO0H1OQiGDPoZB0zRQ - Wanted to Verify Insurance - credit-card
   * P2LR2LujoirpFaLNCAfo - Question About Treatment/Procedure - question-mark-circle
   * kY3rjyzhI4oYngZlntF6 - Questions About Price of Appointment - question-mark-circle
   * oT2IWkZcroGL9giuyRjv - Questions About Balance - question-mark-circle
   * Category - Emergency (All Subcategories) - exclamation
   *
   * If none of the above, have default be: exclamation
   */
  '0sCHAadp3XDh2GJhnIAk': Ban,
  '4oEuKni5bczAnPnomKSb': Clock,
  '9ydkW9fc2SO2f8uZk9Ki': PhoneMissedCall,
  CRNYsENjwsHRPAT9DN5l: Calendar,
  Hzqu91YsyNqvi7KgX2sD: ExclamationCircle,
  N7QhJqSrR9CVEuQecUvt: UserGroup,
  NQH8iM6TEOi0F8FR0IZF: CurrencyDollar,
  S6Nv50rJ92J8h8LehGyl: Calendar,
  V1WYjI9BdC9eyMGRWLxe: Beaker,
  XwUa2mprpZdeI6lqNdJp: PhoneMissedCall,
  YWePxsJTdKYJSiTtsb7Q: ExclamationCircle,
  dxpAd7eyayg0P8bNGSf9: CurrencyDollar,
  f3xqv7gAGz3DVCEIK8Xf: PhoneOutbound,
  gloaUsnP3qoRwqDaPv6H: Heart,
  jAWTyzx8e5GgoyZPBIoN: DotsHorizontal,
  kONFzUE0OqexdHkNCT1o: Calendar,
  xTDsCZ6cpnicnswUxyp4: CreditCard,
  '8lFSNAcPAQjAy4EESs4v': UserCircle,
  KKAGx3ECkasmQ39bC5AG: CreditCard,
  SRPjmzG1GR2hMI6RqwiQ: X,
  SnDPnZijHUhgptjz50pp: LocationMarker,
  SzVra3TKLfjbAEbLe80m: QuestionMarkCircle,
  WaIeHb36IRLTLUjSb0xv: EmojiSad,
  a1uCRGZTHJs8BrSU2ph6: DotsHorizontal,
  dORa31NKqTi2HVWpR4ug: CurrencyDollar,
  iRDioOo22NwsdIUbaacO: Truck,
  '73VO0H1OQiGDPoZB0zRQ': CreditCard,
  P2LR2LujoirpFaLNCAfo: QuestionMarkCircle,
  kY3rjyzhI4oYngZlntF6: QuestionMarkCircle,
  oT2IWkZcroGL9giuyRjv: QuestionMarkCircle
};

export const formatPhone = string => {
  const cleaned = ('' + string).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const isEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const validPhone = value => {
  let phone = value.replace(/\D/g, '');
  return phone.length === 10;
};

export const validEmail = email => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const getFirstLetter = string => {
  if (string) {
    return string.charAt(0).toUpperCase();
  }
};
