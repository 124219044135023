import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../contexts';

const SecureRoute = ({ path, component, location, ...rest }) => {
  const { account, authenticated, auth } = useContext(AppContext);
  const Component = component;
  const userLoaded = auth.isLoaded && account.isLoaded;

  return (
    <Route
      path={path}
      render={props => {
        if (!authenticated && userLoaded) {
          return (
            <Redirect
              to={`/login?redirectUri=${location.pathname}${location.search}`}
            />
          );
        } else if (authenticated && userLoaded) {
          return <Component {...props} {...rest} />;
        }
      }}
    />
  );
};

export default SecureRoute;
